import classes from "./AboutContent.module.scss";

const AboutContent = () => {
  return (
    <section>
      <h1>О компании</h1>
      <p className={classes.about__text}>
        Компания ООО &laquo;СИБСТРОЙ&raquo; уже несколько лет успешно работает
        на&nbsp;рынке жилого домостроения.
      </p>
      <p className={classes.about__text}>
        Мы&nbsp;стремимся к&nbsp;тому, чтобы каждый наш проект соответствовал
        требованиям качества, надежности и&nbsp;соблюдения сроков.
      </p>
      <p className={classes.about__text}>
        Наша команда состоит из&nbsp;опытных специалистов, которые имеют богатый
        опыт в&nbsp;различных сферах строительства. Мы&nbsp;постоянно следим
        за&nbsp;технологиями и&nbsp;трендами в&nbsp;отрасли, чтобы предложить
        вам самые передовые решения. Мы&nbsp;гарантируем использование только
        высококачественных материалов и&nbsp;современного оборудования, чтобы
        обеспечить вам долговечность и&nbsp;надежность наших работ.
      </p>
      <p className={classes.about__text}>
        Мы&nbsp;гордимся нашими успешными проектами и&nbsp;довольными клиентами.
        Наша репутация основана на&nbsp;качестве, профессионализме
        и&nbsp;превосходном обслуживании. Мы&nbsp;стремимся к&nbsp;тому, чтобы
        каждый клиент стал нашим постоянным партнером и&nbsp;рекомендовал нас
        своим знакомым.
      </p>
      <p className={classes.about__text}>
        Если вы&nbsp;ищете надежного партнера в&nbsp;строительстве,
        то&nbsp;вы&nbsp;обратились по&nbsp;адресу.
      </p>
    </section>
  );
};

export default AboutContent;
